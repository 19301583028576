<template>
  <div class="address-page">
    <headerBar :title="i18n.t('bind.title') + 'USDT'"  :showArrow="true" @back="back" ></headerBar>


    <div class="address-form full" >
      <div class="usdt-desc flex-start">
        <div>
          <img :src="noticeUrl" alt="">
        </div>
        <div class="desc">
          请确认绑定地址信息，因地址信息错误导致的任何问题我们概不负责（USDT地址绑定提现需要用户承担区块链矿工费用）
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{i18n.t('bind.label1')}}</div>
        <div class="input ">
          <input type="text" :readonly="readonly" v-model="form.address" :placeholder="i18n.t('bind.placeholder7')">
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{i18n.t('bind.label2')}}</div>
        <div class="input ">
          <input type="text" :readonly="readonly" v-model="form.password" :placeholder="i18n.t('bind.placeholder2')">
        </div>
      </div>
      <div class="btn" >
        <Button @click="submit">{{i18n.t('address.btn')}}</Button>
      </div>
    </div>


  </div>
</template>
<script>
import { Button } from 'vant'
import headerBar from '@/components/header'
import {addCard, addUsdt, doWithdraw, getUsdtList} from "@/api/user";
export default {
  components: {
    headerBar,
    Button
  },
  data() {
    return {
      noticeUrl: require('../../assets/imgs/icon-notice2.png'),
      form: {
        address: '',
        password: '',
      },
      readonly:true,
      userMoney: '0.00',
    }
  },
  computed: {
    show_collection() {
      return this.$store.state.show_collection
    },
  },
  mounted() {
    this.init()
  },
  methods: {
      init(){
          getUsdtList().then(res =>{
              if (res.code === 0) {
                  console.log(res.data.list)
                  if(res.data.list.length === 0) {
                      this.readonly = false
                  } else {
                      this.form.address = res.data.list[0].address
                  }
              }
          })
      },
    back() {
      this.$router.go(-1)
    },
    setMoney(money) {
      this.form.number =  money
    },
    submit() {

        if (!this.form.address) {
          if(this.show_collection === 0) {
            this.$toast({
              message: this.i18n.t('withdraw.placeholder2'),
              icon: 'warning-o'
            })
          } else {
            this.$toast({
              message: this.i18n.t('withdraw.placeholder7'),
              icon: 'warning-o'
            })
          }

          //placeholder7
            return
        }
        let form = new FormData()
        form.append('address', this.form.address)
        form.append('password', this.form.password)
        form.append('protocol', "usdt-trc20")
        addUsdt(form).then(res => {
            if (res.code == 0) {
              this.back()
                this.$toast(res.msg)
            } else {
                this.$toast(res.msg)
            }
        })
    },
    setAll() {
      this.form.money = this.userMoney
    }
  }
}
</script>